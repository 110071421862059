@import '~vuetify/dist/vuetify.min.css';

$mdi-font-path: '../fonts/mdi/';
@import '~@mdi/font/scss/materialdesignicons.scss';

@import 'variables';
@import 'mixins';
@import 'typography';
@import 'auth';
@import 'navigation';
@import 'admin';

html {
  scroll-behavior: smooth;
}

.v-app-bar {
  .v-toolbar__content {
    padding: 0;
  }
}

[v-cloak] {display: none}

.app-header {
  h1 {
    @include clamp(font-size, 1rem, 2rem, 16px, 360px, 1264px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}