.v-app-bar.v-app-bar--fixed {
  z-index: 10;
}

.v-navigation-drawer {
  z-index: 12;
  .notification-button {
    bottom: 56px !important;
    right: 20px;
  }
  &__content {
    .v-list-item {
      &::after {
        content: unset;
      }

      &__icon {
        height: 60px !important;
        width: 60px;
        background-color: darken($primary, 5%);
        display: flex;
        justify-content: center;
        box-shadow: 0px 2px 6px rgba(8, 35, 48, 0.16),
          0px 1px 2px rgba(8, 35, 48, 0.24);
        border-radius: 3px;
      }

      &.active {
        .v-list-item__icon {
          background-color: white;

          .v-icon {
            color: darken($primary, 5%) !important;
          }
        }
      }
    }
  }
  &--mini-variant {
    .notification-button {
      bottom: 42px !important;
      right: 6px;
    }
  }

  &__append {
    margin-bottom: 30px;
    .v-list-item {
      &::after {
        content: unset;
      }

      &__icon {
        display: flex;
        justify-content: center;
        border: 1px solid #fff;
        border-radius: 100%;
        height: 39px !important;
        width: 39px;
      }
    }
  }
}
.admin-menu {
  .v-list-item {
    &::after {
      content: unset;
    }
  }
}
