.user-grid {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 30px;

  .user-item {
    display: flex;
    column-gap: 15px;
    align-items: center;

    .user-title {
      text-align: start;
      color: #a6adaf;
      min-width: 200px;
    }

    .user-value {
      color: #919699;
      font-weight: 700;
    }

    .user-divider {
      border: 1px solid #f7f9fa;
      height: 100%;
    }

    &:nth-child(even) {
      background-color: #f7f9fa;
      .user-divider {
        border: 1px solid #fff;
      }
    }
  }
}